<template>
    <div class="view">
        <img v-if="isWX" src="@/assets/rescueImgs/wx_yd.png" alt="" class="wx_yd" />
        <template v-else-if="source == 'xj'">
            <div class="downloadView" v-if="type == 0">
                <img src="@/assets/appVersion/xjDriver.png" alt="" class="downloadBg" />
                <img src="@/assets/appVersion/xjDLogo.png" alt="" class="dLogo" />
                <div class="dBtn" @click="downLoad(xjDInfo)">下载师傅端APP</div>
            </div>
            <div class="downloadView" v-if="type == 1">
                <img src="@/assets/appVersion/xjMerchant.png" alt="" class="downloadBg" />
                <img src="@/assets/appVersion/xjMLogo.png" alt="" class="MLogo" />
                <div class="mBtn" @click="downLoad(xjMInfo)">下载商家端APP</div>
            </div>
        </template>
        <template v-else>
            <div v-for="(item, index) in appList" class="list" :key="index">
                <div class="li" 
                    v-if="source == item.source && (type == 0 || type == 1 ? item.type == type : true)">
                    <img :src="item.logo" alt="" class="logo">
                    <div class="right">
                        <div class="info">
                            <div class="name">{{ item.name }}</div>
                            <div class="tips">{{ item.tips }}</div>
                        </div>
                        <div class="btn" @click="downLoad(item)">安装</div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import { isWeiXin, getQueryString } from '@/utils/index';
import { appDownload } from '@/apis/unicloud/api';
export default {
    data(){
        return {
            isWX: false,
            source: '',
            type: '99',        //0：只显示技师端  1：只显示商家端
            appList: [
                {
                    appid: '__UNI__ED93B28',
                    name: '同城救援技师端',
                    logo: require('@/assets/logo/tc_logo1.png'),
                    tips: '用于技师接单',
                    source: 'tc',
                    iosLink: 'https://itunes.apple.com/cn/app/qq/id1673205847',
                    type: 0
                },
                {
                    appid: '__UNI__D81101A',
                    name: '同城救援商家端',
                    logo: require('@/assets/logo/tc_logo2.png'),
                    tips: '用于服务商调派订单',
                    source: 'tc',
                    iosLink: 'https://apps.apple.com/cn/app/同城救援商家端/id6478648810',
                    type: 1
                },
                {
                    appid: '__UNI__159D1F4',
                    name: '飞天鱼技师端',
                    logo: require('@/assets/logo/fty_logo.png'),
                    tips: '用于技师接单',
                    source: 'fty',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__E746D90',
                    name: '飞天鱼商家端',
                    logo: require('@/assets/logo/fty_logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'fty',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__F86FA9D',
                    name: '通平救援技师端',
                    logo: require('@/assets/logo/tp_logo.png'),
                    tips: '用于技师接单',
                    source: 'tp',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__9D7A833',
                    name: '通平救援商家端',
                    logo: require('@/assets/logo/tp_logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'tp',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__6F66972',
                    name: '救援云技师端',
                    logo: require('@/assets/logo/public_logo.png'),
                    tips: '用于技师接单',
                    source: 'public',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__236A8F7',
                    name: '救援云商家端',
                    logo: require('@/assets/logo/public_logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'public',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__44AAEC0',
                    name: '车车通师傅端',
                    logo: require('@/assets/logo/cct_logo.png'),
                    tips: '用于技师接单',
                    source: 'cct',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__FFCD6E2',
                    name: '车车通商家端',
                    logo: require('@/assets/logo/cct_logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'cct',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__BC2C0F9',
                    name: '救援师傅技师端',
                    logo: require('@/assets/logo/onekey_logo.png'),
                    tips: '用于技师接单',
                    source: 'yijian',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__9888049',
                    name: '救援师傅商家端',
                    logo: require('@/assets/logo/onekey_logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'yijian',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__0DA6EFD',
                    name: '粤信救援',
                    logo: require('@/assets/logo/yx-logo.png'),
                    tips: '用于技师接单',
                    source: 'yx',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__C9A04D2',
                    name: '粤信商家端',
                    logo: require('@/assets/logo/yx-m-logo.png'),
                    tips: '用于服务商调派订单',
                    source: 'yx',
                    iosLink: '',
                    type: 1
                },
                {
                    appid: '__UNI__6C50391',
                    name: '星际师傅端',
                    logo: require('@/assets/logo/xj-logo.png'),
                    tips: '用于星际师傅服务接单',
                    source: 'xj',
                    iosLink: '',
                    type: 0
                },
                {
                    appid: '__UNI__B937FB8',
                    name: '星际商家端',
                    logo: require('@/assets/logo/xj-logo.png'),
                    tips: '用于星际商家派单结算等',
                    source: 'xj',
                    iosLink: '',
                    type: 1
                },
            ],
            xjDInfo: {
                appid: '__UNI__6C50391',
                name: '星际师傅端',
                logo: require('@/assets/logo/xj-logo.png'),
                tips: '用于星际师傅服务接单',
                source: 'xj',
                iosLink: '',
                type: 0
            },
            xjMInfo: {
                appid: '__UNI__B937FB8',
                name: '星际商家端',
                logo: require('@/assets/logo/xj-logo.png'),
                tips: '用于星际商家派单结算等',
                source: 'xj',
                iosLink: '',
                type: 1
            }
        }
    },
    created(){
        let BASEAPI = process.env.VUE_APP_BASE_API;
        let type = getQueryString('type');
        let source = getQueryString('source');
        switch(BASEAPI){
            case 'https://v2.api.ftysos.com':
                source = 'fty'
                break;
            case 'https://v1.api.hntp614.cn':
                source = 'tp'
                break;
            case 'https://v1.api.yijianjiuyuan.com':
                source = 'yijian'
                break;
            case 'https://api.v2.tc-rescue.com':
                source = source ? source : 'public'
                break;
            case 'https://v1.api.zykjsz.com':
                source = source ? source : 'xj'
                break;
            default:
                source = 'tc'
                break
        }
        if(source){
            this.source = source;
        }
        if(type){
            this.type = type;
        }
        if(isWeiXin()){
            this.isWX = true;
        }
    },
    methods: {
        downLoad(e){
            let ran = navigator.userAgent
            let isAndroid = ran.indexOf('Android') > -1 || ran.indexOf('Linux') > -1
            let isIOS = !!ran.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            let appid = e.appid;
            if(isAndroid){
                appDownload({appid}).then((res)=>{
                    if(res.data.Code != 0 && res.data.data){
                        let url = res.data.data;
                        window.location.href = url;
                    }else{
                        this.$notify({
                            type: 'danger',
                            message: res.data.msg
                        });
                    }
                })
            }
            if(isIOS){
                if(e.iosLink){
                    window.location.href = e.iosLink;
                }else{
                    this.$notify({
                        type: 'danger',
                        message: '暂不支持！'
                    });
                }
            }
        },
    }
}
</script>

<style lang='scss' scoped>
    .list{
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #F8F8F8;
        padding: 12px;
        box-sizing: border-box;
    }
    .li{
        display: flex;
        justify-content: space-between;
        padding: 12px;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 12px;
        .logo{
            width: 70px;
            height: 70px;
            margin-right: 12px;
        }
        .right{
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .info{
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .name{
                    font-size: 16px;
                    color: #333;
                    font-weight: bold;
                }
                .tips{
                    color: #999;
                    font-size: 13px;
                }
            }
            .btn{
                background: var(--theme-color);
                width: 60px;
                height: 32px;
                color: #fff;
                font-size: 14px;
                border-radius: 100px;
                font-weight: bold;
                text-align: center;
                line-height: 32px;
            }
        }
    }
    .wx_yd{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
    }
    .downloadView{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        position: relative;
        .downloadBg{
            width: 100%;
            height: 100%;
        }
        .dLogo{
            width: 100px;
            height: 100px;
            position: absolute;
            bottom: 318px;
        }
        .MLogo{
            width: 100px;
            height: 100px;
            position: absolute;
            top: 51px;
            left: 61px;
        }
        .dBtn, .mBtn{
            width: 289px;
            height: 44px;
            background: var(--theme-color);
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            font-weight: bold;
            font-size: 15px;
            color: var(--theme-btn);
        }
        .dBtn{
            bottom: 179px;
        }
        .mBtn{
            bottom: 128px;
        }
    }
</style>
